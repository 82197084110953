@import "../config";

@mixin sticky-sibebar {
    position: sticky;
    top: 0;
    left: 0;
}

@mixin position-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.same-width {
    display: flex;
    justify-content: space-between;
    p {
        // width: 70%;
    }
}

.no-break{
    white-space: nowrap;
}

.started-border-bottom{
    border-bottom: 2px solid $theme-color;
    margin: 0px 5px;
    .started-border{
        padding: 5px;
    }
}

.today-info{
    padding: 5px;
    span{
        font-weight: 500;
    }
}

.side-container {
    @include sticky-sibebar;
    background-color: rgb(231, 223, 223);
    // position: sticky;
    // top: 0;
    // left: 0;
}
.side-container-small{
    .side-container{
        display: flex;
        justify-content: space-around;
        .started-border{
            border: 2px solid $theme-color;
        }
    }
}
@media screen and (min-width: 660px) {
    .side-container-small{
        display: none !important;
    }
}
@media screen and (max-width: 660px) {
    .side-conatiner-lg{
        display: none !important;
    }
    .today-info{
        display: flex;
        justify-content: space-around;
    }
}

.arrows{
    display: flex;
    justify-content: space-between;
    margin: 15px;
    .left-arrow, .right-arrow{
        font-size: 30px;
        font-weight: 800;
    }
}

.filter-availability {
    // background-color: $bg-filter; 
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    // align-items: center;
    

    .btn-search-availability {
        .Search {
            text-align: center;
            width: 180px;
            border: 0;
            border-radius: 10px;
            background-color: $theme-contrast;
            color: $secondary-color;
        }
    }
}

.pick-start-date input[type="text"],
.pick-end-date input[type="text"] {
    border-radius: 05px;
    border: 1px solid #ccc;
    height: 35px;
    width: 200px;
}

.width-flex {
    display: flex;
    // flex-direction: column;
}

.dinner,
.lunch {
    text-transform: uppercase;
    text-orientation: upright;
    writing-mode: vertical-rl;
    font-weight: 600;
    width: 40px;
    @include position-center;
}

.lunch {
    background-color: $theme-color;
    color: $secondary-color;
}

.dinner {
    background-color: $theme-contrast;
    color: $secondary-color;
}

.table-availability {
    margin: 10px 20px 20px 10px;

    td {
        height: 100px;
        // border: 3px solid gray;
        background-color: rgb(195, 218, 195) !important;
    }

    .pax-count {
        font-weight: 600;
    }

    .available {
        border: 3px solid green;
    }

    .unavailable {
        background-color: #ff000044 !important;
        border: 5px solid red;
    }
    .payment {
        background-color: #7989e244 !important;
        border: 5px solid rgb(0, 119, 255);
    }
    tr,
    th {
        // white-space: nowrap;
        border: 1px solid black;
    }
}
.table-responsive-lunch, .table-responsive-dinner{
    overflow-x: scroll;
    width: 100%;
    .headings{
        background-color: $secondary-color;
    }
}

.table-lunch,
.table-dinner {
    display: flex;

    table {
        width: 100%;
        text-align: center;
    }
}